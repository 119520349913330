/* call font with name font-family */

@font-face {
    font-family: Poppins;
    src: local("Poppins"),
        url("../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 900 !important;
}
@font-face {
    font-family: Poppins-900;
    src: local("Poppins-900"),
        url("../assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: 900 !important;
}
@font-face {
    font-family: Poppins-900i;
    src: local("Poppins-900i"),
        url("../assets/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
    font-weight: 900 !important;
    font-style: italic;
}
@font-face {
    font-family: Poppins-700;
    src: local("Poppins-700"),
        url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700 !important;
}
@font-face {
    font-family: Poppins-700i;
    src: local("Poppins-700i"),
        url("../assets/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 700 !important;
}
@font-face {
    font-family: Poppins-800;
    src: local("Poppins-800"),
        url("../assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800 !important;
}
@font-face {
    font-family: Poppins-800i;
    src: local("Poppins-800i"),
        url("../assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800 !important;
    font-style: italic;
}
@font-face {
    font-family: Poppins-200;
    src: local("Poppins-200"),
        url("../assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200 !important;
}
@font-face {
    font-family: Poppins-200i;
    src: local("Poppins-200i"),
        url("../assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200 !important;
    font-style: italic;
}
@font-face {
    font-family: Poppins-400i;
    src: local("Poppins-400i"),
        url("../assets/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
    font-weight: 400 !important;
    font-style: italic;
}
@font-face {
    font-family: Poppins-300;
    src: local("Poppins-300"),
        url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300 !important;
}
@font-face {
    font-family: Poppins-300i;
    src: local("Poppins-300i"),
        url("../assets/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
    font-weight: 300 !important;
    font-style: italic;
}
@font-face {
    font-family: Poppins-500;
    src: local("Poppins-500"),
        url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500 !important;
}
@font-face {
    font-family: Poppins-500i;
    src: local("Poppins-500i"),
        url("../assets/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
    font-weight: 500 !important;
}@font-face {
    font-family: Poppins-400;
    src: local("Poppins-400"),
        url("../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400 !important;
}
@font-face {
    font-family: Poppins-600;
    src: local("Poppins-600"),
        url("../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600 !important;
}
@font-face {
    font-family: Poppins-100;
    src: local("Poppins-100"),
        url("../assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
    font-weight: 100 !important;
}
@font-face {
    font-family: Poppins-100i;
    src: local("Poppins-100i"),
        url("../assets/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
    font-weight: 100 !important;
}
